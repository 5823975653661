.promo {
  width: 100%;
  box-sizing: border-box;
  padding: 20px 40px 0;
  transition: all 0.3s;
}

.promo__substrate {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  padding: 160px 0 30px;
  border-radius: 10px;
  background: #272727;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  transition: all 0.3s;
}

.promo__title {
  color: #FFF;
  width: 730px;
  margin: 0 0 151px;
  text-align: center;  
  font-size: 50px;
  font-style: normal;
  font-weight: 400;
  line-height: 58px;
  letter-spacing: -2px;
  transition: all 0.3s;
}

@media screen and (max-width: 1279px) {
  .promo {
    padding: 20px 20px 0;
  }
  .promo__substrate {
    padding: 350px 0 30px;
  }
  .promo__title {
    width: 680px;
    margin: 0 0 314px;
    font-size: 40px;
    line-height: 52px;
    letter-spacing: -1.6px;
  }
}

@media screen and (max-width: 767px) {
  .promo {
    padding: 14px 14px 0;
  }
  .promo__substrate {
    padding: 220px 0 17px;
  }
  .promo__title {
    width: 292px;
    margin: 0 0 212px;
    font-size: 29px;
    line-height: 37px;
    letter-spacing: -1.16px;
  }
}