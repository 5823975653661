.footer {
  max-width: 1280px;
  width: 100%;
  margin-top: auto;
  box-sizing: border-box;
  padding: 0 70px;
  display: flex;
  flex-direction: column;
  transition: all 0.3s;
}

.footer__text {
  color: #8B8B8B;
  margin: 0;
  padding: 79px 0 20px;
  border-bottom: 1px solid #424242;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;  
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.footer__sources {
  width: 100%;
  display: flex;
  padding: 20px 0;
  transition: all 0.3s;
}

.footer__author {
  color: #FFF;
  margin: 0 auto 0 0;
  font-feature-settings: 'clig' off, 'liga' off;  
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.footer__company {
  color: #FFF;
  margin: 0 20px 0 0;
  text-decoration: none;
  font-feature-settings: 'clig' off, 'liga' off;  
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  transition: all 0.4s;
}

.footer__company:last-of-type {
  margin: 0;
}

.footer__company:hover {
  opacity: 0.7;
}

@media screen and (max-width: 1279px) {
  .footer {
    max-width: 768px;
    padding: 0 30px;
  }
}

@media screen and (max-width: 767px) {
  .footer {
    max-width: 320px;
    padding: 0 10px;
  }
  .footer__text {
    padding: 77px 0 20px;
    font-size: 12px;
  }
  .footer__sources {
    padding: 30px 0 20px;
    flex-direction: column;
    align-items: center;
  }
  .footer__author {
    color: #8B8B8B;
    margin: 30px 0 0;
    font-size: 12px;
    order: 3;
  }
  .footer__company {
    margin: 0 0 12px;
    font-size: 12px;
  }
}