.nav-tab {
  display: flex;
}

.nav-tab__link {
  color: #FFF;
  height: 36px;
  width: 96px;
  margin-right: 10px;
  border-radius: 10px;
  background: #303030;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  transition: all 0.4s;
  cursor: pointer;
}

.nav-tab__link:last-of-type {
  margin-right: 0;
}

.nav-tab__link:hover {
  opacity: 0.8;
}

@media screen and (max-width: 767px) {
  .nav-tab__link {
    width: 82px;
    height: 26px;
    margin-right: 6px;
    border-radius: 6px;
    font-size: 10px;
  }
}