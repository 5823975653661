.movies-card-list {
  padding: 70px 70px 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 1fr;
  gap: 30px;
}

@media screen and (max-width: 1279px) {
  .movies-card-list {
    padding: 70px 30px 0;
    grid-template-columns: repeat(2, 1fr);
    gap: 28px 33px;
  }
}

@media screen and (max-width: 767px) {
  .movies-card-list {
    padding: 40px 10px 0;
    grid-template-columns: 1fr;
    gap: 30px;
  }
}
