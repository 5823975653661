.navigation {
  width: 100%;
  grid-column: 2/4;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  justify-items: center;
  transition: all 0.3s;
}

.navigation__group {
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  justify-items: center;
  gap: 16px;
  transition: all 0.3s;
}

.navigation__link {
  color: #FFF;
  text-decoration: none;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  cursor: pointer;
  transition: all 0.4s;
}

.navigation__link:hover {
  opacity: 0.8;
}

.navigation__link_type_main-page {
  display: none;
}

.navigation__link_type_with-background {
  width: 100px;
  height: 32px;
  border-radius: 20px;
  background: #313131;
  justify-self: end;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  line-height: 16px;
}

.navigation__link_type_current {
  position: relative;
  font-weight: 500;
  pointer-events: none;
}

.navigation__link_type_current::after {
  content: "";
  width: 100%;
  height: 2px;
  display: none;
  background-color: #FFF;
  position: absolute;
  bottom: -6px;
  left: 0;
}

.shadow {
  width: 0;
  height: 100vh;
  background-color: #000;
  opacity: 0;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  transition: width 0s, opacity 0.3s;
}

.shadow_visible {
  width: 100%;
  opacity: 0.3;
}

@media screen and (max-width: 1279px) {
  .navigation {
    height: 100vh;
    width: calc((100% / 3 * 2) + 7px);
    box-sizing: border-box;
    padding: 160px 0 90px 1px;
    padding-right: calc((100vw - 768px) / 2 + 30px);
    background: #202020;
    grid-column: 2/3;
    grid-template-columns: auto;
    grid-template-rows: repeat(2, auto);
    align-items: flex-start;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 1;
    opacity: 0;
    transform: translateX(100%);
  }
  .navigation__group {
    grid-template-columns: auto;
    grid-template-rows: repeat(3, 21px);
    gap: 29px;
    transform: translateY(100vw);
    transition: transform ease-out 0.6s;
  }
  .navigation__link { 
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 18px;
    font-weight: 500;
    line-height: normal;
  }
  .navigation__link_type_main-page {
    display: inline-block;
  }
  .navigation__link_type_with-background {
    justify-self: auto;
    align-self: flex-end;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    transform: translateY(100vw);
    transition: transform ease-out 0.6s;
  }
  .navigation__link_type_current::after {
    display: inline-block;
  }
  .navigation_opened {
    opacity: 1;
    transform: translateX(0);
  }
  .navigation_opened .navigation__group {
    transform: translateY(0);
  }
  .navigation_opened .navigation__link_type_with-background {
    transform: translateY(0);
  }
}

@media screen and (max-width: 767px) {
  .navigation {
    width: 100%;
    padding: 145px 0 118px;
  }
  .navigation__group {
    gap: 25px;
  }
  .navigation__link_type_current::after {
    bottom: -8px;
  }
}