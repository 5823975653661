.about-me {
  width: 100%;
  box-sizing: border-box;
  padding: 110px 70px 125px;
  transition: all 0.3s;
}

.about-me__title {
  color: #FFF;
  width: 100%;
  margin: 0 0 66px;
  box-sizing: border-box;
  padding-bottom: 24px;
  border-bottom: 1px solid #DADADA;  
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.88px;
  transition: all 0.3s;
}

.about-me__article {
  margin-bottom: 100px;
  display: flex;
  justify-content: space-between;
  transition: all 0.3s;
}

.about-me__experience {
  width: 600px;
  display: flex;
  flex-direction: column;
  transition: all 0.3s;
}

.about-me__name {
  color: #FFF;
  margin: 0 0 18px;  
  font-size: 50px;
  font-style: normal;
  font-weight: 400;
  line-height: 58px;
  letter-spacing: -2px;
  transition: all 0.3s;
}

.about-me__subtitle {
  color: #FFF;
  margin: 0 0 26px;
  font-feature-settings: 'clig' off, 'liga' off;  
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.15px;
  transition: all 0.3s;
}

.about-me__text {
  color: #FFF;
  height: 100%;
  margin: 0;
  font-feature-settings: 'clig' off, 'liga' off;  
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  transition: all 0.3s;
}

.about-me__photo {
  width: 270px;
  height: 400px;
  border-radius: 10px;
  object-fit: cover;
  pointer-events: none;
  transition: all 0.3s;
}

.about-me__link {
  color: #FFF;
  text-decoration: none;  
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  transition: all 0.4s;
}

.about-me__link:hover {
  opacity: 0.7;
}

@media screen and (max-width: 1279px) {
  .about-me {
    padding: 90px 50px;
  }
  .about-me__article {
    margin-bottom: 90px;
  }
  .about-me__experience {
    width: 364px;
  }
  .about-me__name {
    margin: 0 0 16px;
    font-size: 40px;
    line-height: 40px;
    letter-spacing: -1.6px;
  }
  .about-me__subtitle {
    margin: 0 0 20px;
    font-size: 12px;
    line-height: 18px;
  }
  .about-me__text {
    font-size: 12px;
    line-height: 18px;
  }
  .about-me__photo {
    width: 255px;
    /* height: 307px; */
  }
}

@media screen and (max-width: 767px) {
  .about-me {
    padding: 70px 14px;
  }
  .about-me__title {
    margin: 0 0 60px;
    padding-bottom: 29px;
    font-size: 18px;
    letter-spacing: -0.72px;
  }
  .about-me__article {
    margin-bottom: 70px;
    flex-direction: column;
  }
  .about-me__experience {
    width: 292px;
    order: 2;
  }
  .about-me__name {
    margin: 0 0 20px;
    font-size: 30px;
    line-height: normal;
    letter-spacing: -1.2px;
  }
  .about-me__subtitle {
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 0.09px;
  }
  .about-me__text {
    margin-bottom: 40px;
    font-size: 11px;
    line-height: 16px;
  }
  .about-me__photo {
    height: 352px;
    width: 100%;
    margin-bottom: 40px;
    order: 1;
  }
}