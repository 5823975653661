.preloader {
  padding: 48px 0 80px;
  display: flex;
  justify-content: center;
}

.preloader__image {
  height: 40px;
  width: 40px;
  animation: rotatePreloaderImg 2s linear infinite running 
}

.preloader__button {
  color: #FFF;
  width: 320px;
  height: 36px;
  margin: 0;
  border: 1px solid #424242;
  border-radius: 6px;
  padding: 0;
  background: none;
  text-align: center;  
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  transition: all 0.4s;
  cursor: pointer;
}

.preloader__button:hover {
  opacity: 0.8;
}

.preloader__text {
  color: #FFF;
  text-align: center;  
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

@media screen and (max-width: 1279px) {
  .preloader {
    padding: 43px 0 80px;
  }
}

@media screen and (max-width: 767px) {
  .preloader {
    padding: 54px 0 83px;
  }
  .preloader__button {
    width: 240px;
  }
}

@keyframes rotatePreloaderImg {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}