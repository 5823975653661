.techs {
  width: 100%;
  box-sizing: border-box;
  padding: 100px 70px;
  background: #272727;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.3s;
}

.techs__title {
  color: #FFF;
  width: 100%;
  margin: 0 0 90px;
  box-sizing: border-box;
  padding-bottom: 24px;
  border-bottom: 1px solid #DADADA;
  
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.88px;
  transition: all 0.3s;
}

.techs__subtitle {
  color: #FFF;
  margin: 0 0 26px;
  text-align: center;
  
  font-size: 50px;
  font-style: normal;
  font-weight: 400;
  line-height: 58px;
  letter-spacing: -2px;
  transition: all 0.3s;
}

.techs__text {
  color: #FFF;
  width: 460px;
  margin: 0 0 100px;
  text-align: center;
  
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.56px;
  transition: all 0.3s;
}

.techs__cases {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 10px;
  transition: all 0.3s;
}

.techs__cases-block {
  color: #FFF;
  width: 90px;
  height: 60px;
  margin: 0;
  border-radius: 10px;
  background: #303030;
  display: flex;
  justify-content: center;
  align-items: center;  
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.56px;
}

@media screen and (max-width: 1279px) {
  .techs {
    padding: 90px 50px;
  }
  .techs__title {
    margin: 0 0 80px;
  }
  .techs__subtitle {
    margin: 0 0 22px;
  }
  .techs__text {
    margin: 0 0 83px;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.48px;
  }
  .techs__cases-block {
    width: 84px;
    height: 57px;
  }
}

@media screen and (max-width: 767px) {
  .techs {
    padding: 70px 18px;
    background: none;
  }
  .techs__title {
    margin: 0 0 60px;
    padding-bottom: 29px;
    font-size: 18px;
    letter-spacing: -0.72px;
  }
  .techs__subtitle {
    margin: 0 0 24px;
    font-size: 30px;
    line-height: normal;
    letter-spacing: -1.2px;
  }
  .techs__text {
    width: 292px;
    margin: 0 0 50px;
    font-size: 11px;
    line-height: 16px;
    letter-spacing: -0.44px;
  }
  .techs__cases {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, 1fr);
  }
}
