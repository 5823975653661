.not-found {
  min-height: 100vh;
  box-sizing: border-box;
  padding: 245px 0 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.not-found__title {
  color: #FFF;
  margin: 0 0 5px;  
  font-size: 140px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  transition: all 0.3s;
}

.not-found__text {
  color: #FFF;
  margin: 0;  
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  transition: all 0.3s;
}

.not-found__button {
  color: #4285F4;
  margin: 184px 0 0;
  border: none;
  padding: 0;
  background: none;
  text-align: center;  
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  transition: all 0.4s;
}

.not-found__button:hover {
  opacity: 0.7;
}

@media screen and (max-width: 1300px) {
  .not-found {
    padding: 407px 0 222px;
  }
}
@media screen and (max-width: 767px) {
  .not-found {
    padding: 330px 0 33px;
  }
  .not-found__title {
    margin: 0 0 9px;
    font-size: 80px;
  }
  .not-found__text {
    font-size: 12px;
  }
  .not-found__button {
    margin: auto 0 0;
    font-size: 12px;
  }
}