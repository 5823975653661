.search-form {
  padding: 80px 70px 0;
}

.search-form__form {
  display: flex;
  flex-direction: column;
}

.search-form__fieldset {
  width: 100%;
  margin: 0;
  border: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.search-form__input {
  color: #FFF;
  width: 93%;
  height: 50px;
  margin: 0 10px 0 0;
  border: none;
  padding: 0 0 0 20px;
  border-radius: 8px;
  background: #2F2F2F;
}

.search-form__input::placeholder {
  color: #8B8B8B;
}

.search-form__input:focus {
  outline: black 1px solid;
}

.search-form__button {
  width: 47px;
  height: 47px;
  border: none;
  padding: 0;
  background: url(../../images/find-3.svg) no-repeat center;
  background-size: contain;
  transition: all 0.4s;
  cursor: pointer;
}

.search-form__button:hover {
  opacity: 0.8;
}

.search-form__error-message {
  color: #EE3465;
  width: 100%;
  text-align: center;
  font-size: 10px;
  font-weight: 400;
}

@media screen and (max-width: 1279px) {
  .search-form {
    padding: 80px 30px 0;
  }
  .search-form__input {
    width: 89%;
  }
}

@media screen and (max-width: 767px) {
  .search-form {
    padding: 80px 14px 0;
  }
  .search-form__input {
    width: 77%;
    height: 44px;
    padding: 0 0 0 13px;
  }
  .search-form__button {    
    width: 44px;
    height: 44px;
  }
}

