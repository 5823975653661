.movies-card {
  max-width: 100%;
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-template-rows: 201px max-content;
  gap: 14px 30px;
  position: relative;
}

.movies-card__link {
  height: 100%;
  width: 100%;
  grid-column: 1/3;
}

.movies-card__image {
  height: 100%;
  width: 100%;
  border-radius: 6px;
  object-fit: cover;
  pointer-events: none;
}

.movies-card__name {
  color: #FFF;
  min-height: 46px;
  max-width: 100%;
  margin: 0 0 0 14px;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.movies-card__duration {
  color: #8B8B8B;
  width: 52px;
  height: 18px;
  margin: 0 14px 0 0;
  border-radius: 3px;
  background: #2F2F2F;
  display: flex;
  justify-self: end;
  align-items: center;
  justify-content: center;  
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 10px;
}

.movies-card__button {
  color: #FFF;
  width: 72px;
  height: 21px;
  margin: 0;
  border: none;
  border-radius: 30px;
  padding: 0;
  background: #313131;
  position: absolute;
  top: 14px;
  right: 14px;
  opacity: 0;
  text-align: center;  
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 9px;
  transition: all 0.4s;
  cursor: pointer;
}

.movies-card:hover .movies-card__button {
  opacity: 1;
}

.movies-card__button_type_saved {
  opacity: 1;
  background: url(../../images/save3.svg) no-repeat right;
}

.movies-card__button_type_delete {
  background: url(../../images/d3.svg) no-repeat right;
}

@media screen and (max-width: 1279px) {
  .movies-card {
    grid-template-rows: 190px 35px;
    gap: 28px 30px;
  }
  .movies-card__name {
    margin: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .movies-card__duration {
    margin: 0;
  }
}

@media screen and (max-width: 767px) {
  .movies-card {
    grid-template-rows: 168px 24px;
    gap: 16px 30px;
  }
  .movies-card__name {    
    font-size: 12px;
  }
  .movies-card__button {
    opacity: 1;
  }
}
