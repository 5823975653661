.user-form {
  width: 396px;
  box-sizing: border-box;
  padding-top: 70px;
  display: flex;
  flex-direction: column;
  transition: all 0.3s;
}

.user-form__heading {
  color: #FFF;
  margin: 40px 0;  
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.user-form__inputs {  
  min-height: 380px;
  width: 100%;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  transition: all 0.3s;
}

.user-form__label {
  color: #8B8B8B;
  display: flex;
  flex-direction: column;  
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.user-form__label:last-of-type {
  margin-bottom: auto;
}

.user-form__input {
  color: #FFF;
  height: 44px;
  outline: none;
  margin-top: 10px;
  border: none;
  border-radius: 8px;
  padding-left: 15px;
  background: #2F2F2F;  
  font-size: 13px;
}

.user-form__input:focus {
  outline: black 1px solid;
}

.user-form__input:invalid {
  color: #EE3465;
}

.user-form__error-message {
  color: #EE3465;
  margin: 10px 0;
}

.user-form__error-message {
  margin: 0 0 20px;
  text-align: center;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  font-weight: 400;
}

.user-form__button {
  color: #FFF;
  height: 45px;
  border: none;
  border-radius: 3px;
  background: #4285F4;
  text-align: center;  
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  transition: 0.4s;
  cursor: pointer;
}

.user-form__button_disabled {
  color: #C2C2C2;
  background: #F8F8F8;
  pointer-events: none;
}

.user-form__button:hover {
  opacity: 0.8;
}

.user-form__note {
  display: flex;
  justify-content: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.user-form__link {
  color: #4285F4;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.4s;
}

.user-form__link:hover {
  opacity: 0.7;
}

.user-form__text {
  color: #8B8B8B;
  margin: 0;
  padding-right: 6px;
}

@media screen and (max-width: 1279px) {
  .user-form {
    padding: 232px 0;
  }
}

@media screen and (max-width: 767px) {
  .user-form {
    min-height: 100vh;
    width: 100%;
    padding: 56px 30px 33px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .user-form__heading {    
    margin: 50px 0 80px;
  }
  .user-form__inputs {
    flex: 1;
    margin-bottom: 14px;
  }
  .user-form__button {
    font-size: 12px;
  }
  .user-form__note {    
    font-size: 12px;
  }
}