.portfolio__title {
  color: #8B8B8B;
  margin: 0 0 50px;
  font-feature-settings: 'clig' off, 'liga' off;  
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  transition: all 0.3s;
}

.portfolio__list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.portfolio__item {
  box-sizing: border-box;
  padding: 20px 0;
  border-bottom: 1px solid #424242;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.portfolio__item:first-of-type {
  padding-top: 0;
}

.portfolio__item:last-of-type {
  padding-bottom: 0;
  border: none;
}

.portfolio__link {
  color: #FFF;
  text-decoration: none;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: 50px;
  letter-spacing: -1.2px;
  transition: all 0.4s;
}

.portfolio__link:hover {
  opacity: 0.7;
}

.portfolio__link::after {
  content: "↗";
  line-height: 60px;
  position: absolute;
  right: 0;
}

@media screen and (max-width: 1279px) {
  .portfolio__title {
    font-size: 18px;
    line-height: 20px;
  }
  .portfolio__link {
    font-size: 28px;
    letter-spacing: -1.12px;
  }
  .portfolio__link::after {
    font-size: 28px;
    letter-spacing: -1.12px;
  }
}

@media screen and (max-width: 767px) {
  .portfolio__title {
    margin: 0 0 40px;
    font-size: 14px;
    line-height: 20px;
  }
  .portfolio__link {
    font-size: 18px;
    line-height: 28px;
    letter-spacing: -0.72px;
  }
  .portfolio__link::after {
    font-size: 18px;
    line-height: 28px;
    letter-spacing: -0.72px;
  }
}